import { useSlate } from "slate-react";
import { BaseEditor, Editor, Transforms } from "slate";
import { useState } from "react";

const alignList = [
  { name: "left", icon: "/fontbox/left_align.png" },
  { name: "center", icon: "/fontbox/center_align.png" },
  { name: "right", icon: "/fontbox/right_align.png" },
  { name: "justify", icon: "/fontbox/justify_align.png" },
];

export const AlignButton = () => {
  const editor = useSlate();
  const [isOpen, setIsOpen] = useState(false);
  const [alignment, setAlignment] = useState("left");

  return (
    <>
      {isOpen && (
        <>
          <div
            onClick={() => {
              setIsOpen(false);
            }}
            className="fixed w-full h-full z-[100000] top-0 left-0"
          />
          <ul className="absolute top-0 -left-0 bg-white z-[200000] border-[1px] border-solid shadow-lg">
            {alignList.map((align) => (
              <img
                onClick={() => {
                  setAlignment(align.name);
                  toggleAlignMark(editor, align.name);
                  setIsOpen(false);
                }}
                className="w-[22px] h-full hover:brightness-95 cursor-pointer"
                key={align.name}
                src={align.icon}
                alt={align.name}
              />
            ))}
          </ul>
        </>
      )}
      <button
        onClick={() => setIsOpen(true)}
        onMouseDown={(e) => {
          e.preventDefault();
        }}
        className="relative flex flex-col items-center justify-center w-[22px] h-full gap-1 hover:brightness-95 bg-white"
      >
        <img
          src={`/fontbox/${alignment}_align.png`}
          alt="align"
          className="w-auto h-[22px]"
        />
      </button>
    </>
  );
};

const toggleAlignMark = (editor: BaseEditor, textAlign: string) => {
  (Transforms.setNodes as any)(
    editor,
    { textAlign },
    { match: (n: any) => Editor.isBlock(editor, n) }
  );
};
