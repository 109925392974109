import React, { HTMLAttributes } from "react";
import { BackgroundButton } from "./Background";
import { BoldButton } from "./Bold";
import { ItalicButton } from "./Italic";
import { UnderlineButton } from "./Underline";
import { CancelButton } from "./Cancel";
import { ColorButton } from "./Color";
import { AlignButton } from "./Align";
import { FontSizeButton } from "./FontSize";
import { FontFamily } from "./FontFamily";

interface InlineToolsProps extends HTMLAttributes<HTMLDivElement> {
  formatText: (val: string) => void;
  fontState: any;
}

const InlineTools = ({ fontState, formatText, ...props }: InlineToolsProps) => {
  return (
    <section
      style={{
        padding: "288px",
        borderRadius: "5px",
      }}
      {...props}
      onMouseDown={(e) => {
        e.preventDefault();
      }}
      className="flex items-center gap-2 shadow-lg bg-white p-2 z-[10000] relative"
    >
      <FontFamily fontState={fontState} />
      <FontSizeButton />
      <BoldButton />
      <ColorButton />
      <ItalicButton />
      <AlignButton />
    </section>
  );
};

export default InlineTools;
