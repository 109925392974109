import { useSlate } from "slate-react";
import { BaseEditor, Editor, Transforms } from "slate";
import { useState } from "react";
import ArrowIcon from "../../assets/ArrowIcon";

const fontRecord: Record<string, string> = {
  "": "기본서체",
  "Nanum Gothic": "나눔고딕",
  nanummyeongjo: "나눔명조",
  nanumbarungothic: "나눔바른고딕",
  nanumsquare: "나눔스퀘어",
  MaruBuri: "마루부리",
  Restart: "다시시작해",
  Bareun_hipi: "바른히피",
  Daughter_handwriting: "우리딸 손글씨",
};

const fontArray = [
  { name: "기본서체", fontFamily: "" },
  { name: "나눔고딕", fontFamily: "Nanum Gothic" },
  { name: "나눔명조", fontFamily: "nanummyeongjo" },
  { name: "나눔바른고딕", fontFamily: "nanumbarungothic" },
  { name: "나눔스퀘어", fontFamily: "nanumsquare" },
  { name: "마루부리", fontFamily: "MaruBuri" },
  { name: "다시시작해", fontFamily: "Restart" },
  { name: "바른히피", fontFamily: "Bareun_hipi" },
  { name: "우리딸 손글씨", fontFamily: "Daughter_handwriting" },
];

export const FontFamily = ({ fontState }: { fontState: any }) => {
  const editor = useSlate();
  const [isOpen, setIsOpen] = useState(false);
  console.log(20, fontState);
  const [font, setFont] = useState({
    name: fontRecord[fontState?.[0].children?.[0]?.fontFamily || ""],
  });

  return (
    <>
      {isOpen && (
        <>
          <div
            onClick={() => {
              setIsOpen(false);
            }}
            className="fixed w-full h-full z-[100000] top-0 left-0"
          />
          <ul className="absolute top-0 left-0 bg-white z-[2000000] border-[1px] border-solid shadow-lg">
            {fontArray.map((font) => {
              return (
                <div
                  onMouseDown={(e) => {
                    e.preventDefault();
                  }}
                  onClick={() => {
                    setIsOpen(false);
                    toggleLineHeight(editor, font);
                    setFont(font);
                  }}
                  className="py-2 px-4 w-40 h-full hover:brightness-95 bg-white cursor-pointer z-[300000]"
                  key={font.name}
                  style={{
                    fontFamily: font.fontFamily,
                  }}
                >
                  {font.name}
                </div>
              );
            })}
          </ul>
        </>
      )}
      <button
        onClick={() => {
          setIsOpen(true);
        }}
        onMouseDown={(e) => {
          e.preventDefault();
        }}
        className="relative text-[12px] text-[#333] flex items-center gap-3"
      >
        {font.name}
        <ArrowIcon width={9} />
      </button>
    </>
  );
};

const toggleLineHeight = (editor: BaseEditor, font: any) => {
  (Transforms.setNodes as any)(
    editor,
    { fontFamily: font.fontFamily },
    { match: (n: any) => Editor.isBlock(editor, n) }
  );
};
